import { useRef, useState, useContext, useEffect } from "react";
import { ResponsiveContext, ThemeContext } from "grommet";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { useLocation } from "@reach/router";

export const useScrollPercentage = () => {
  const scrollRef = useRef(null);
  const [scrollPercentage, setScrollPercentage] = useState(NaN);

  const reportScroll = (e) => {
    setScrollPercentage(getScrollPercentage(e.target));
  };

  useEffect(() => {
    const node = scrollRef.current;
    if (node !== null) {
      node.addEventListener("scroll", reportScroll, { passive: true });
      if (Number.isNaN(scrollPercentage)) {
        setScrollPercentage(getScrollPercentage(node));
      }
    }
    return () => {
      if (node !== null) {
        node.removeEventListener("scroll", reportScroll);
      }
    };
  }, [scrollPercentage]);

  return [scrollRef, Number.isNaN(scrollPercentage) ? 0 : scrollPercentage];
};

function getScrollPercentage(element) {
  if (element === null) {
    return NaN;
  }
  const height = element.scrollHeight - element.clientHeight;
  return Math.round((element.scrollTop / height) * 100);
}

export const useResponsive = () => {
  const size = useContext(ResponsiveContext);
  const themeSizes = useContext(ThemeContext);

  return {
    themeSizes: themeSizes?.global?.edgeSize,
    size,
    isDesktop: size === "medium" || size === "large",
    isMobile: size === "xxsmall" || size === "xsmall" || size === "small",
    isSmallMobile: size === "xxsmall" || size === "xsmall",
    isTablet: size === "small",
  };
};


export const useMousePosition = ()  => {
  const [mousePosition, setMousePosition] = useState({ x: null, y: null });

  useEffect(() => {
    const mouseMoveHandler = (event) => {
      const { clientX, clientY } = event;
      setMousePosition({ x: clientX, y: clientY });
    };
    document.addEventListener("mousemove", mouseMoveHandler);

    return () => {
      document.removeEventListener("mousemove", mouseMoveHandler);
    };
  }, []);

  return mousePosition;
}