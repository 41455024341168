import React, { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { SplitText } from 'gsap/SplitText';
import CustomEase from 'gsap/CustomEase';

const AnimatedParagraph = ({ children, className, activate = false }) => {
  const paragraphRef = useRef(null);
  const tlRef = useRef(null);
  const splitRef = useRef(null);
  const [hasActivated, setHasActivated] = useState(false);

  useEffect(() => {
    gsap.registerPlugin(SplitText, CustomEase);

    return () => {
      if (tlRef.current) {
        tlRef.current.kill();
      }
      if (splitRef.current) {
        splitRef.current.revert();
      }
    };
  }, []);

  useEffect(() => {
    if (activate && !hasActivated) {
      setHasActivated(true);

      const paragraph = paragraphRef.current;
      if (!paragraph) return;

      splitRef.current = new SplitText(paragraph, {
        type: "lines",
        linesClass: "lineParent"
      });

      splitRef.current.lines?.forEach(line => {
        new SplitText(line, {
          type: "lines",
          linesClass: "lineChild"
        });
      });

      const lineChildren = paragraph.querySelectorAll(".lineChild");
      gsap.set(lineChildren, { yPercent: 100 });

      tlRef.current = gsap.timeline({ paused: true });

      tlRef.current.to(lineChildren, {
        duration: 1.25,
        yPercent: 0,
        stagger: 0.1,
        ease: CustomEase.create("custom", "M0,0 C0.083,0.294 0.182,0.677 0.448,0.867 0.579,0.96 0.752,1 1,1 "),
      });
    }

    if (tlRef.current) {
      if (activate) {
        tlRef.current.play();
      } else {
        tlRef.current.reverse();
      }
    }
  }, [activate, hasActivated]);

  return (
    <p ref={paragraphRef} className={className}>
      {children}
    </p>
  );
};

export default AnimatedParagraph;