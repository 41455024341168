import React, { useCallback, useLayoutEffect, useRef, useState, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from "gatsby-plugin-image";

import SymbolHealth from "../assets/svgs/healthSymbol.svg";
import SymbolCommercial from "../assets/svgs/commercialSymbol.svg";
import SymbolEducation from "../assets/svgs/educationSymbol.svg";
import SymbolFacilities from "../assets/svgs/facilitiesSymbol.svg";

import CategoryHealth from "../assets/svgs/health-no-icon.svg";
import CategoryCommercial from "../assets/svgs/commercial-no-icon.svg";
import CategoryEducation from "../assets/svgs/education-no-icon.svg";
import CategoryFacilities from "../assets/svgs/facilities-no-icon.svg";
import Button from "./Button";
import AnimatedParagraph from "./AnimatedParagraph";


gsap.registerPlugin(ScrollTrigger);

const divisions = [
  {
    "name": "health",
    "logo": <CategoryHealth />,
    "symbol": <SymbolHealth />,
    "image": <StaticImage src={"../assets/images/health.jpg"} alt={"Health background"} loading={"eager"} placeholder={"none"} />,
    "tabletImage": <StaticImage src={"../assets/images/health-tablet.jpg"} alt={"Health background"} />,
    "description": "Our dedicated team fulfils clinical projects of every scope. A leading healthcare contractor with over a decade of experience working in live environments across the South East.",
    "cta": <Button to="/health" color={"health"} text={"Go to Health"} textClassName={"tablet:w-[200px]"} iconOnlyOnMobile={true} />,
  },
  {
    "name": "commercial",
    "logo": <CategoryCommercial />,
    "symbol": <SymbolCommercial />,
    "image": <StaticImage src={"../assets/images/commercial.jpg"} alt={"Commercial background"} loading={"eager"} placeholder={"none"} />,
    "tabletImage": <StaticImage src={"../assets/images/commercial-tablet.jpg"} alt={"Commercial background"} />,
    "description": "From modern office transformations to dynamic retail environments, our Commercial team ensures every project is executed with a commitment to operational excellence.",
    "cta": <Button to="/commercial" color={"commercial"} text={"Go to Commercial"} textClassName={"tablet:w-[200px]"} iconOnlyOnMobile={true} />,
  },
  {
    "name": "education",
    "logo": <CategoryEducation />,
    "symbol": <SymbolEducation />,
    "image": <StaticImage src={"../assets/images/education.jpg"} alt={"Education background"} loading={"eager"} placeholder={"none"} />,
    "tabletImage": <StaticImage src={"../assets/images/education-tablet.jpg"} alt={"Education background"} />,
    "description": "Bauvill Education delivers a range of education construction services enabling academic institutes to operate at their full potential. ",
    "cta": <Button to="/education" color={"education"} text={"Go to Education"} textClassName={"tablet:w-[200px]"} iconOnlyOnMobile={true} />,
  },
  {
    "name": "facilities",
    "logo": <CategoryFacilities />,
    "symbol": <SymbolFacilities />,
    "image": <StaticImage src={"../assets/images/facilities.jpg"} alt={"Facilities background"} loading={"eager"} placeholder={"none"} />,
    "tabletImage": <StaticImage src={"../assets/images/facilities-tablet.jpg"} alt={"Facilities background"} />,
    "description": "Bauvill Facilities specialise in maintaining Hard FM contracts for a range of NHS and commercial clients across the South East.",
    "cta": <Button to="/facilities" color={"facilities"} text={"Go to Facilities"} textClassName={"tablet:w-[200px]"} iconOnlyOnMobile={true} />,
  }
];

const Division = ({ division, index, divisionCount, setActiveDivision, activeDivision, hasHiddenDivision = false, style = "light" }) => {
  const containerRef = useRef(null);
  const descriptionRef = useRef(null);
  const backgroundRef = useRef(null);
  const ctaRef = useRef(null);
  const symbol1Ref = useRef(null);
  const symbol2Ref = useRef(null);
  const [showDescription, setShowDescription] = useState(false);

  const animate = useCallback((isActive) => {
    const baseWidth = 100 / divisionCount;
    const expandedWidth = baseWidth * 1.5;

    const mm = gsap.matchMedia();

    mm.add("(min-width: 1280px)", () => {
      if (containerRef.current) {
        gsap.to(containerRef.current, {
          width: isActive ? `${expandedWidth}%` : `${baseWidth}%`,
          duration: 0.3,
          ease: "linear"
        });
      }

      if (backgroundRef.current) {
        gsap.to(backgroundRef.current, {
          opacity: isActive ? 1 : 0,
          duration: 0.3,
          ease: "power2.inOut"
        });
      }

      if (descriptionRef.current && ctaRef.current) {
        gsap.to([descriptionRef.current, ctaRef.current], {
          opacity: isActive ? 1 : 0,
          visibility: isActive ? "visible" : "hidden",
          duration: isActive ? 0.3 : 0.2,
          delay: isActive ? 0.3 : 0,
          ease: "power1.inOut",
          onStart: () => {
            setShowDescription(isActive);
          }
        });
      }
    });

    mm.add("(max-width: 1279px)", () => {
      if (containerRef.current) {
        gsap.set(containerRef.current, { width: "100%" });
      }
      if (backgroundRef.current) {
        gsap.set(backgroundRef.current, { opacity: 1 });
      }
      if (ctaRef.current) {
        gsap.set(ctaRef.current, { opacity: 1, visibility: "visible" });
      }
    });

    return () => mm.revert(); // Clean up the media queries
  }, [index, divisionCount]);

  const symbolAnimationDuration = 0.75;
  const symbolAnimationEase = "power4.inOut";

  useLayoutEffect(() => {
    animate(activeDivision === index);

    return () => {
      if (containerRef.current) {
        gsap.killTweensOf(containerRef.current);
      }
      if (backgroundRef.current) {
        gsap.killTweensOf(backgroundRef.current);
      }
      if (descriptionRef.current && ctaRef.current) {
        gsap.killTweensOf([descriptionRef.current, ctaRef.current]);
      }
    };
  }, [activeDivision, index, animate]);


  useEffect(() => {
    const handleResize = () => {
      animate(activeDivision === index);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [animate, activeDivision, index]);

  const handleInteraction = () => {
    setActiveDivision(index);
  }

  const onMouseEnter = () => {
    handleInteraction();

    gsap.fromTo(symbol1Ref.current, {
      yPercent: 0,
    }, {
      yPercent: -100,
      duration: symbolAnimationDuration,
      ease: symbolAnimationEase
    });
    gsap.fromTo(symbol2Ref.current, {
      yPercent: 0,
    }, {
      yPercent: -100,
      duration: symbolAnimationDuration,
      ease: symbolAnimationEase
    });
  }

  const onMouseLeave = () => {
    // Move symbol 1 down (fromTo gsap)
    gsap.fromTo(symbol1Ref.current, {
      yPercent: -100,
    }, {
      yPercent: 0,
      duration: symbolAnimationDuration,
      ease: symbolAnimationEase
    });
    gsap.fromTo(symbol2Ref.current, {
      yPercent: -100,
    }, {
      yPercent: 0,
      duration: symbolAnimationDuration,
      ease: symbolAnimationEase
    });
  }

  return (
    <div
      id={`division-showcase-division-${index}`}
      ref={containerRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={handleInteraction}
      tabIndex={0}
      aria-pressed={activeDivision === index}
      className={`relative grid grid-rows-3 px-6 py-8 cursor-default overflow-hidden border-secondary w-full desktop:h-auto desktop:cursor-pointer ${hasHiddenDivision ? "desktop:w-1/3" : "desktop:w-[25%]"}`}
    >
      {division.symbol && (
        <div>
          <div className="size-36 overflow-hidden hidden z-10 desktop:block relative">
            <div ref={symbol1Ref} className={"relative h-full w-full p-2 *:h-full *:w-full *:relative"}>
              {division.symbol}
            </div>
            <div ref={symbol2Ref} className={"relative h-full w-full p-2 *:h-full *:w-full *:relative"}>
              {division.symbol}
            </div>
          </div>
        </div>
      )}

      <div
        className="flex justify-between items-center z-10 h-full absolute top-0 bottom-0 left-3 right-3 tablet:flex-col desktop:flex-row desktop:relative desktop:top-auto desktop:bottom-auto desktop:left-auto desktop:right-auto">
        {division.logo && (
          <div
            className="*:w-[33vw] tablet:*:w-[25vw] tablet:justify-start tablet:w-full tablet:pt-3 desktop:pt-0 desktop:w-auto desktop:*:w-[10vw]">
            {division.logo}
          </div>
        )}

        {division.cta && (
          <div ref={ctaRef} className="opacity-0 invisible flex items-end z-10 tablet:justify-end tablet:w-full tablet:pb-4 tablet:pr-2 desktop:pb-0 desktop:pr-0 desktop:w-auto desktop:opacity-100 desktop:visible">
            {division.cta}
          </div>
        )}
      </div>

      <div ref={descriptionRef} className={`opacity-0 invisible flex items-end z-10 desktop:opacity-100 desktop:visible`}>
        <AnimatedParagraph className={"font-medium text-light"} activate={showDescription}>
          {division.description}
        </AnimatedParagraph>
      </div>

      {division.image && (
        <div
          ref={backgroundRef}
          className={`absolute left-0 top-0 h-full w-full origin-center opacity-0 *:w-full *:h-full *:object-cover *:*:w-full *:*:h-full *:*:object-cover desktop:opacity-100 desktop:origin-left ${hasHiddenDivision ? "desktop:w-[50vw]" : " desktop:w-[40vw]"}`}
        >
          <div className={"block desktop:hidden"}>
            {division.tabletImage}
          </div>
          <div className={"hidden desktop:block"}>
            {division.image}
          </div>
        </div>
      )}

      {index !== divisionCount - 1 && (
        <span className={`absolute hidden right-0 top-0 h-full w-[3px] origin-top bg-secondary desktop:block ${style === "dark" && "bg-white/25"}`} />
      )}
    </div>
  )
}

function getDivisions(hiddenDivision = "") {
  return divisions.filter(division => division.name.toLowerCase() !== hiddenDivision.toLowerCase());
}

const DivisionShowcase = ({showcaseType = "all", hiddenDivision = "", style = "light"}) => {
  const [activeDivision, setActiveDivision] = useState(null);

  useEffect(() => {
    const handleGlobalMouseMove = (e) => {
      const divisions = document.querySelectorAll('[id^="division-showcase-division-"]');
      let found = false;
      divisions.forEach((div, index) => {
        if (div.contains(e.target)) {
          setActiveDivision(index);
          found = true;
        }
      });
      if (!found) setActiveDivision(null);
    };

    document.addEventListener('mousemove', handleGlobalMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleGlobalMouseMove);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = parseInt(entry.target.id.split('-').pop());
          if (entry.isIntersecting) {
            entry.target.addEventListener('mouseenter', () => setActiveDivision(index));
          } else {
            entry.target.removeEventListener('mouseenter', () => setActiveDivision(index));
          }
        });
      },
      { threshold: 0.5 }
    );

    document.querySelectorAll('[id^="division-showcase-division-"]').forEach((div) => {
      observer.observe(div);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <div className={"content-container pb-6 desktop:w-[100vw] desktop:px-0 desktop:py-0"}>
      <div className={"py-12 desktop:py-16"}>
        {showcaseType === "all" ? (
          <h2 className={`text-left text-2xl text-secondary font-bold tablet:text-3xl tablet:text-right tablet:pr-[20vw] ${style === "dark" && "text-white"}`}>Our Divisions</h2>
        ) : (
          <h2 className={`text-left text-2xl text-secondary font-bold tablet:text-3xl tablet:text-left tablet:pl-[15vw] ${style === "dark" && "text-white"}`}>Our Other Divisions</h2>
        )}
      </div>

      <div className={"custom-h-screen w-full grid grid-cols-1 gap-4 desktop:flex desktop:flex-row desktop:gap-0"}>
        {getDivisions(hiddenDivision).map((division, index) => {
          if (hiddenDivision.toLowerCase() === division.name.toLowerCase()) return null;

          const hasHiddenDivision = hiddenDivision !== "";

          return (
            <Division
              key={index}
              division={division}
              index={index}
              divisionCount={getDivisions(hiddenDivision).length}
              setActiveDivision={setActiveDivision}
              activeDivision={activeDivision}
              hasHiddenDivision={hasHiddenDivision}
              style={style}
            />
          )
        })}
      </div>
    </div>
  )
}

export default DivisionShowcase;